import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { BLink } from '../components/content-components'

const SingleTagPage = ({ data, pageContext }) => {  
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`
	return  (
    <Layout pageTitle={tagHeader}>
      <h1>{tagHeader}</h1>
      {edges.map(({ node }) => {
        return (
          <div className="md:text-lg grid grid-flow-col auto-cols-max gap-5">
            <div>
              {node.frontmatter.date}
            </div>
            <div>
              <BLink to={`/${node.slug}`}>{node.frontmatter.title}</BLink>
            </div>
          </div>
        )
      })}
    </Layout>
  )
}

export const q = graphql`
query($tag: String) {
  allMdx(
    limit: 2000
    sort: { fields: [frontmatter___date], order: DESC }
    filter: { frontmatter: { tags: { in: [$tag] } } }
  ) {
    totalCount
    edges {
      node {
        slug
        frontmatter {
          title
          date (formatString: "D/MM/YYYY")
        }
      }
    }
  }
}`

export default SingleTagPage